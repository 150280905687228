import { useRouter } from 'next/router';

import { AuthFormContainer } from '~/app';
import { useNextgenIntegrationContext } from '~/app/hooks/nextgen-integration';
import { useAuth } from '~/app/auth';

export interface AuthPageProps {
}

export default function AuthPage(props: AuthPageProps) {
  const router = useRouter();
  const { isNextgenIntegrated } = useNextgenIntegrationContext();
  const { firebaseUser } = useAuth();

  if (isNextgenIntegrated) {
    if (firebaseUser) {
      window.location.assign('/lcoh');
    } else {
      window.location.assign('/auth');
    }

    return null;
  }
  
  const goToLcoh = () => {
    return router.push('/lcoh/model');
  }

  return (
    <AuthFormContainer
      onAlreadyAuthenticated={goToLcoh}
      onLoginSuccess={goToLcoh}
      onRegistrationSuccess={goToLcoh}
    />
  );
}
